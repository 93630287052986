// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";

@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "maps";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";


// Layout & components
@import "~bootstrap/scss/root";

$hr-color : #115256;
// $hr-margin-y : 0;
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";

@import "buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";

$accordion-border-width: 0rem;
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
$progress-bg : #bedadd;
$progress-bar-bg : #115256;
$progress-height : 35px;
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";

$modal-bg: #e4f0f0;
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
@import "login";
@import "icons";


html,
body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

body {

  font-family: 'Sen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-section {
  height: calc(100% - 9rem);
}

.public-main-section {
  height: calc(100% - 3rem);
}

.height-scroll {
  height: calc(100% - 3rem);
}

.main-header {
  height: 6rem;
}

.main-footer {
  height: 3rem;
}

.content-header {
  height: 3rem;

  .content-header-top,
  .content-header-bottom {
    border: none;
    height: 100%;
  }
}

@include media-breakpoint-down(xl) {
  .height-scroll {
    height: calc(100% - 6rem);
  }

  .content-header {
    height: 6rem;

    .content-header-top {
      border-bottom: 1px solid $green1;
      height: 3rem;
    }

    .content-header-bottom {
      height: 3rem;
    }
  }
}

.course-card {
  width: 24rem;
  height: 24rem;

  img {
    height: 14rem;
  }
}

.level1-card {
  width: 16rem;
  height: 15rem;

  img {
    width: 8rem;
    height: 8rem;
  }

  .card-footer {
    height: 4rem;
  }
}

@include media-breakpoint-down(xl) {
  .course-card {
    width: 17rem;
    height: 17rem;

    img {
      height: 8rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .course-card {
    width: 13rem;
    height: 13rem;

    img {
      height: 6rem;
    }
  }
}

.custom-scroller::-webkit-scrollbar {
  width: 0px;
}

.custom-scroller,
.scroller {
  -webkit-overflow-scrolling: touch;
}

.scroller::-webkit-scrollbar {
  width: 16px;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: $green1;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.organ:hover {
  background-color: $blue1 !important;
}

.topic {
  color: #000000 !important;

  &:hover {
    background-color: $green1 !important;

    a {
      color: #ffffff !important;
    }
  }
}

.model-object {
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  model-viewer {
    width: 100%;
    height: 100%;
  }

  .model-part {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  .rotate-line {
    top: -20px;
    position: relative;
    margin-top: auto;

    a {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .loader1 {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin: -38px 0 0 -38px;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #115256;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

// My Profile
.form-div-col {
  background-color: #e3eff0;
  color: #000;
  padding: 35px;

  label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 0;
  }
}

.my-profile-icon-img {
  background: #e3eff0;
  width: 199px;
  height: 200px;
  color: #FFF;
  text-align: center;
  font-size: 100px;
  // padding: 25px;
  font-weight: bold;
}

.welcome-bg {
  background: rgb(123, 179, 171);
  background: radial-gradient(circle, rgba(123, 179, 171, 1) 0%, rgba(39, 107, 106, 1) 57%, rgba(48, 113, 119, 1) 100%);
}

.loading-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
}

.logo-div {
  padding: 100px 150px 100px 150px;
}

.pointer {
  cursor: pointer;
}

.z-index-minus-1 {
  z-index: -1;
}

.z-index-minus-2 {
  z-index: -1;
}

.z-index-minus-3 {
  z-index: -1;
}

.z-index-minus-4 {
  z-index: -1;
}

.coming-soon-bg1 {
  background: radial-gradient(farthest-corner at 100% 0%, rgba(169, 255, 226, 0.4) 10%, rgba(255, 255, 255, 0));
}

.coming-soon-bg2 {
  background: radial-gradient(farthest-side at 0% 60%, rgba(176, 239, 255, 0.2) 50%, rgba(255, 255, 255, 0));
  ;
}

.left-gradient-content {
  background: hsla(179, 21%, 49%, 1);

  background: linear-gradient(45deg, hsla(179, 21%, 49%, 1) 3%, hsla(180, 45%, 29%, 1) 27%, hsla(180, 45%, 29%, 1) 59%, hsla(173, 22%, 43%, 1) 83%, hsla(180, 21%, 50%, 1) 95%);

  background: -moz-linear-gradient(45deg, hsla(179, 21%, 49%, 1) 3%, hsla(180, 45%, 29%, 1) 27%, hsla(180, 45%, 29%, 1) 59%, hsla(173, 22%, 43%, 1) 83%, hsla(180, 21%, 50%, 1) 95%);

  background: -webkit-linear-gradient(45deg, hsla(179, 21%, 49%, 1) 3%, hsla(180, 45%, 29%, 1) 27%, hsla(180, 45%, 29%, 1) 59%, hsla(173, 22%, 43%, 1) 83%, hsla(180, 21%, 50%, 1) 95%);
}

.coming-soon-badge {
  background-image: linear-gradient(to right, #3e777d, #5ba59d);
  background-image: -moz-linear-gradient(to right, #3e777d, #5ba59d);
  background-image: -webkit-linear-gradient(to right, #3e777d, #5ba59d);
}
.table-option {
  display: flex;
  margin: 5px 2px;
  gap: 5px;
}
.table-option {
  white-space: nowrap;
}
.table-input {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 3px;
}
.table-unit {
  width: 15px;
  height: 15px;
  border: 1px solid lightgray;
}

.preview-table table, th, td {
  border: 2px solid #d9eaeb !important;
}

table{
  border-collapse: collapse;
}
.preview-table tr:first-child > td{
  background: #cce3e4;
  text-align: center;
}

table{
  width:100%;
}
td{
  height: 50px;
  padding:0 5px;
}
.popup-wrapper{
  display: inline;
  position: relative;
}
.popup{
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;

}
button{
  cursor: pointer;
}
 ::marker{
  font-weight: bold;
}
.userList {
  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }

  tbody {
    height: 500px;
    overflow-y: auto;
  }

  tbody td,
  thead th {
    width: 20%;
    float: left;
  }

}