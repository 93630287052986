@import "~bootstrap/scss/buttons";

@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @if $color == "light" {
        @include button-variant(
          $value,
          $value,
          $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
          $hover-border: shade-color($value, $btn-hover-border-shade-amount),
          $active-background: shade-color($value, $btn-active-bg-shade-amount),
          $active-border: shade-color($value, $btn-active-border-shade-amount)
        );
      } @else if $color == "dark" {
        @include button-variant(
          $value,
          $value,
          $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
          $hover-border: tint-color($value, $btn-hover-border-tint-amount),
          $active-background: tint-color($value, $btn-active-bg-tint-amount),
          $active-border: tint-color($value, $btn-active-border-tint-amount)
        );
      } @else if $color == "green1" {
        @include button-variant(
          $value,
          $value,
          $hover-background: $green3,
          $hover-border: $green3,
        );
      } @else if $color == "green1a" {
        @include button-variant(
          $value,
          $value,
          $hover-background: $green1,
          $hover-border: $green1,
        );
      } @else if $color == "green3" {
        @include button-variant(
          $value,
          $value,
          $hover-background: $green1,
        );
      } @else if $color == "green3a" {
        @include button-variant(
          $value,
          $value,
          $color: $green1,
          $hover-color: $green1,
          $hover-background: $green3,
          $hover-border: $green3,
        );
      } @else if $color == "green3b" {
        @include button-variant(
          $value,
          $value,
          $color: $green1,
          $hover-color: $white,
          $hover-background: $green1,
          $hover-border: $green1,
        );
      } @else if $color == "green4" {
        @include button-variant(
          $value,
          $value,
          $active-background: $green3,
          $active-border: $green3,
        );
      } @else {
        @include button-variant($value, $value);
      }
    }
  }