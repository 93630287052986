.login {

    // .form-check {
    //     padding-left: 0 !important;
    // }

    // .form-check-label {
    //     padding-top: 1rem !important;
    //     padding-bottom: 1rem !important;
    // }


    // .radio-btn-sectondary {
    //     background-color: #f1f7f7 !important;
    // }

    // .btn-check:checked+.btn,
    // :not(.btn-check)+.btn:active,
    // .btn:first-child:active,
    // .btn.active,
    // .btn.show {
    //     background-color: #c6dcde !important;
    //     border-color: #c6dcde !important;
    // }



    
    #booking-form li {
        list-style: none;
    }

    /* Progress Bar */
    .progress-wrap {
        margin: auto;
        display: table;
    }

    .line-progress-bar {
        display: flex;
        margin: auto;
        width: 100%;
    }

    .line {
        height: 6px;
        width: 65px;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-bottom-color: #fdf5e5;
        position: absolute;
        margin-top: 10px;
        z-index: -1;
    }

    .line.active {
        border-bottom-color: #dfcaa2 !important;
        width: 25px !important;
        margin-left: 23px;
        z-index: 1;
    }

    .stpe2-line.active {
        border-bottom-color: #dfcaa2 !important;
        width: 75px !important;
        margin-left: 23px;
        z-index: -1;
    }

    .stpe2-line {
        margin-left: 40px;
    }

    .progress-wrap div ul {
        display: flex;
        width: 120px;
        list-style: none;
        padding: 0px;
        margin: initial;
        justify-content: space-between;
        z-index: 1;
    }

    .progressbar-dots {
        display: inline-flex;
        border: #949494 solid 4px;
        background: #333333;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #dfcaa2;
        border: 0px solid #fdf5e5;
        background: #fdf5e5;
    }

    .progressbar-dots span {
        font-size: 12px;
        line-height: 12px;
        position: absolute;
        margin-top: 0;
        /* width: 75px; */
        float: left;
        margin-left: 0;
        //    display: none;
    }

    .progressbar-dots.active {
        color: #907848;
        border: 0px solid #dfcaa2;
        background: #dfcaa2;
    }

    /*  Tab */
    .tab-pane {
        display: none;
    }

    .tab-pane:first-child {
        display: block;
    }

    .lognin-btn {
        width: 100px;
    }
    .step-spacing {
        margin: 7px 20px 0 20px;
        display: inline-block;
    }

    .forgot-password:hover{
        color: $green1 !important;
    }
}