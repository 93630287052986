$all-colors: map-merge($green-colors, $gray-colors);
$all-colors: map-merge($all-colors, $blue-colors);
$all-colors: map-merge($all-colors, $extra-colors);
$theme-colors: map-merge($theme-colors, $custom-colors);


$custom-theme-colors-rgb: map-loop($all-colors, to-rgb, "$value");

:root,
[data-bs-theme="light"] {

    @each $color,
    $value in $custom-theme-colors-rgb {
        --#{$prefix}#{$color}-rgb: #{$value};
    }
}

$utilities-colors: $custom-theme-colors-rgb;

$custom-font-sizes: (
  7: $font-size-base * 0.875,
  8: $font-size-base * 0.75,
);

$font-sizes: map-merge($font-sizes, $custom-font-sizes);