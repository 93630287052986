$primary: #1A5356;
$secondary: #9AC6C9;
$headerbg : #f1f7f7;
$primaryText : #1a5356;
$cardbg : #e3eff0;
$litebtn : #c6dcde;
$gray : #696969;
$darkgray : #595959;

$green1: #1A5356;
$green2: #9AC6C9;
$green3: #C6DCDE;
$green4: #E3EFF0;
$green5: #F1F7F7;
$green6: #105256;

$gray1: #595959;
$gray2: #696969;
$gray3: #F0F1F2;
$gray4: #F8F8F8;
$gray5: #acafaf;

$blue1: #A0D0DC;
$blue2: #E4F5FA;

$danger: #dc3545;

$custom-colors: (
    "green1": $green1,
    "green1a": $green1,
    "green2": $green2,
    "green3": $green3,
    "green3a": $green3,
    "green3b": $green3,
    "green4": $green4,
);
$green-colors: (
    "green1": $green1,
    "green2": $green2,
    "green3": $green3,
    "green4": $green4,
    "green5": $green5,
    "green6": $green6,
);
$gray-colors: (
    "gray1": $gray1,
    "gray2": $gray2,
    "gray3": $gray3,
    "gray4": $gray4,
    "gray5": $gray5,
);

$blue-colors: (
    "blue1": $blue1,
    "blue2": $blue2,
);
$extra-colors: (
    "danger": $danger,
);

$dropdown-min-width: 15rem;
$dropdown-color: $green1;
$dropdown-bg: $green3;
$dropdown-link-color: $green1;
$dropdown-link-hover-color: #ffffff;
$dropdown-link-hover-bg: $green1;
$dropdown-border-color: none;
$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;
$dropdown-header-color: $green1;

$input-disabled-bg: $green5;


$accordion-body-padding-x: 1rem;
$accordion-button-padding-y: 0.75rem;
$accordion-button-padding-x: 0.75rem;
$accordion-button-active-bg: $gray3;
$accordion-button-bg: $gray3;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 1.5rem;
$accordion-icon-color: $green1;
$accordion-icon-active-color: $green1;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-color: $gray2;

$form-range-track-bg: $green2;
$form-range-thumb-active-bg: $green1;

$btn-link-color: $green1;
$btn-link-hover-color: black;